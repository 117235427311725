import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {LANG} from "../common/consts";
import CatoImage from '../components/cato-image';
import CatoLink from '../components/cato-link';
import LinkBox from '../components/resource-link-box-with-tag';
import useDeviceDetect from '../hooks/useDeviceDetect';
import MasterclassLogo from '../assets/images/masterclass/master class logo final-03.svg';
import Etay from '../assets/images/masterclass/etay.png';
import DarkReading from '../assets/images/masterclass/dark reading.png';
import SecurityWeek from '../assets/images/masterclass/securityweek.png';
import ComputerWeekly from '../assets/images/masterclass/computerweekly.png';
import Podchaser from '../assets/images/masterclass/podchaser.png';
import Demonstrated from '../assets/images/masterclass/Demonstrated cybersecurity - icon.svg';
import ThoughtLeaders from '../assets/images/masterclass/thought-leaders.svg';
import Research from '../assets/images/masterclass/Research - icon.svg';
import Disinformation from '../assets/images/masterclass/LP  From Disinformation to Deepfakes.png';
import SupplyChain from '../assets/images/masterclass/Supply chain attacks & Critical infrastructure.png';
import Ransomware from '../assets/images/masterclass/Ransomware Attackers, Defenders, and FBI’s Perspective.png';
import OSINT from '../assets/images/masterclass/OSINT and The Remote Workforce.png';
import MasterclassMobile from '../assets/images/masterclass/master class logo - mobile.png';
import CriticalInfrastructure from '../assets/images/masterclass/800X500 Targeting critical infrastructure.png';
import Webinar from '../assets/images/masterclass/425x272 trendimon - Social Engineering Attacks and Open-Source Intelligence Usage.png';
import {ReactComponent as Play} from '../assets/images/masterclass/play icon - normal.svg';
import '../assets/styles/pages/masterclass.scss';


const Masterclass = ({data, pageContext, location}) => {
    const {wpPage} = data;
    const {masterclassFields} = wpPage;
    const {featuredArticle} = masterclassFields;

    const {isSmallDesktop, isMobile} = useDeviceDetect();
    return (
        <Layout
            className="masterclass"
            yoastData={wpPage.seo || null}
            seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
            lang={LANG.ENG}
            whiteMenu={false}
            seoPath={location.pathname}
            translations={pageContext.translations}
            greenLogoOverride={true}
        >
            <section className="banner">
                <div className="speaker-image">
                    <CatoImage img={masterclassFields.speakerImage} />
                    {isMobile && <CatoImage img={MasterclassMobile}  className={'masterclass-logo'}/>}
                </div>
                <div className="text">
                    {!isMobile && <CatoImage img={MasterclassLogo} />}
                    {masterclassFields.heading && <h1>{masterclassFields.heading}</h1>}
                    {masterclassFields.subheading && <div><p>{masterclassFields.subheading}</p></div>}
                    <aside className={'featured-in-logos'}>
                        {masterclassFields.featuredInText && <span>{masterclassFields.featuredInText}</span>}
                        {masterclassFields.featuredLogos?.length &&  <ul className="logos">
                            {masterclassFields.featuredLogos.map((item,i) => <li key={`featured-logo-${i}`}><CatoImage img={item.logo}/></li>)}
                        </ul>}
                    </aside>
                </div>
                <div className="featured-article">
                    <LinkBox
                        title={featuredArticle.title || featuredArticle.resource[0].title}
                        img={featuredArticle.image || featuredArticle.resource[0].resourceFields.resourceSmallIamge}
                        summary={featuredArticle.excerpt || featuredArticle.resource[0].resourceFields.resourceSmallDescription}
                        path={featuredArticle.link || featuredArticle.resource[0].resourceFields.resourceLink}
                        type={featuredArticle.type || featuredArticle.resource[0].resourceFields.resourceType}
                        featured={true}
                    />
                </div>
            </section>
            <section className="content">
                <aside className="icon-text">
                    {masterclassFields.iconsAndText?.length &&
                    <ul>
                        {masterclassFields.iconsAndText.map((item, i) => (
                            <li key={`icon-and-text-${i}`}>
                                <CatoImage img={item.icon}/>
                                <span dangerouslySetInnerHTML={{__html: item.text }}/>
                            </li>
                        ))}
                    </ul>
                    }
                </aside>
                <div className="article-list">
                    {masterclassFields.articleList.map((item, i) => {
                            const title = item.title ? item.title : item.resource ? item.resource[0].title : null;
                            const img = item.image ? item.image : item.resource ? item.resource[0].resourceFields.resourceSmallImage : null;
                            const summary = item.excerpt ? item.excerpt : item.resource ? item.resource[0].resourceFields.resourceShortDescription : null;
                            const path = item.link ? item.link : item.resource ? item.resource[0].resourceFields.resourceLink : null;
                            const type = item.type ? item.type : item.resource ? item.resource[0].resourceFields.resourceType : null;


                            return (<LinkBox
                                title={title}
                                img={img}
                                summary={summary}
                                path={path}
                                type={type}
                                key={`link-box-${i}`}
                            />)
                        }
                    )}
                </div>
            </section>

        </Layout>
    )
}

export default Masterclass;

export const query = graphql`
    query masterclassPageQuery($id: String!) {
        wpPage(id: { eq: $id}) {
            title
            slug
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
            ...YoastData
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            masterclassFields {
                speakerImage {
                    altText
                    localFile {
                            publicURL
                        } 
                }
                heading
                subheading
                featuredInText
                featuredLogos {
                    logo {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                }
                featuredArticle {
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    title
                    excerpt
                    type
                    link
                    resource {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceShortDescription
                                resourceType
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 340) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
                iconsAndText {
                    icon {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    text
                }
                articleList {
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    title
                    excerpt
                    type
                    link
                    resource {
                        ... on WpResource {
                            id
                            title
                            resourceFields {
                                resourceShortDescription
                                resourceType
                                resourceSmallImage {
                                    altText
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 340) {
                                                ...GatsbyImageSharpFluid_withWebp_noBase64
                                            }
                                        }
                                        extension
                                        publicURL
                                    }
                                }
                                resourceLink
                            }
                        }
                    }
                }
                
            }
        }
        
    }
`
